import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"


export default () => (
    <Container>
        <Helmet>
            <title>Press & Reviews | An Exquisite Meal</title>
            <meta name="description" content="Download a press kit." />
        </Helmet>

        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>

                <h2 class="headline">Press & reviews.</h2>

                <blockquote>

                    <p>"Those who enjoy the surrealist works of Yorgos Lanthimos (The Lobster, Dogtooth) will be quite at home here."</p>

                    <p>"The cast is uniformly engaging, each actor embracing the faux-confidence they espouse while demonstrating their crippling cowardice in times of true jeopardy."</p>

                    <p>-- <a href="https://filmthreat.com/reviews/an-exquisite-meal/" target="_new">Film Threat</a></p>

                </blockquote>

                
                <p></p>
                <p></p>

                <h3>Press kit.</h3>

                <div class="description">
                    <p><a href="../An_Exquisite_Meal_Press_Kit_2021_12_16.pdf" target="_blank">Download the press kit</a> (last updated December 16, 2021)</p>
                </div>

            </div>
        </div>
        <Footer>
        </Footer>
    </Container>

)

